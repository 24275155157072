<template>
  <div class="brandStory">
    <div class="part_1">
      <img :src="data.url + 'brandStory/story_1.png'" alt="" class="bg" />
      <img :src="data.url + 'brandStory/story_2.png'" alt="" class="bg2" />
      <img :src="data.url + 'logo.png'" alt="" class="logo" />
      <div class="storyText">
        <div>2024，NU SKIN 40岁了，感恩有您</div>
        <div>40年来，我们不断成长、探索、变革、链接</div>
        <div>为我们共同创造的精彩未来赋能</div>
      </div>
      <img class="mortIcon" :src="data.url + 'more.png'" alt="" />
    </div>
    <div class="tab">
      <div 
        :class="{'tabItem':true,'selected':data.tab==1}"
        @click="changeTab(1)">
        WHO<br/>WE ARE
        <img
         v-if="data.tab==1"
        :src="data.url+'brandStory/tabbg3.png?1'" 
        class="tabBg1">
      </div>
      <div 
        :class="{'tabItem':true,'selected':data.tab==2}"
        @click="changeTab(2)">
        Nu Skin <br/>
        梦开始的地方
        <img
        v-if="data.tab==2"
        :src="data.url+'brandStory/tabbg21.png?1'" 
        class="tabBg2">
      </div>
      <div 
        :class="{'tabItem':true,'selected':data.tab==3}"
        @click="changeTab(3)">
        充满信心 <br/>
        直面挑战
        <img
        v-if="data.tab==3"
        :src="data.url+'brandStory/tabbg21.png?1'" 
        class="tabBg2">
      </div>
      <div 
        :class="{'tabItem':true,'selected':data.tab==4}"
        @click="changeTab(4)">
        最新品牌 <br/>
        形象大片
        <img
        v-if="data.tab==4"
        :src="data.url+'brandStory/tabbg3.png?1'" 
        class="tabBg3">
      </div>
    </div>
    <div class="part_2">
      <div class="video">
        <video
          ref="myVideo"
          width="100%"
          style="object-fit: contain"
          preload
          controls="controls"
          :poster="data.poster"
          :src="data.videoUrl"
        ></video>
      </div>
      <div class="downLoad">
        <img :src="data.url + 'brandStory/story_3.png'" alt="" class="bg" />
        <div class="downLoadBtn" @click="lookVideo">分享和下载视频</div>
        <img class="mortIcon" :src="data.url + 'more.png'" alt="" />
      </div>
    </div>
    <div class="part_3">
      <div class="lunbo">
        <div
          v-if="data.activeIndex == 0"
          class="firstImg swiper-slide swiper-slide-prev"
        >
          <img
            src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/brandStory/img_4.png"
            alt=""
          />
        </div>
        <swiper
          ref="mySwiper"
          :slidesPerView="'auto'"
          :centeredSlides="true"
          :spaceBetween="0"
          :slideToClickedSlide="true"
          @activeIndexChange="onSlideChange"
          @swiper="setSwiperRef"
        >
          <template v-for="(item, index) in data.imgList" :key="index">
            <SwiperSlide>
              <img :src="item" alt="" />
            </SwiperSlide>
          </template>
        </swiper>
        <div
          v-if="data.activeIndex == data.imgList.length - 1"
          class="lastImg swiper-slide swiper-slide-next"
        >
          <img
            src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/brandStory/img_1.png"
            alt=""
          />
        </div>
      </div>
      <div class="pagination">
        <template v-for="(item, index) in data.imgList" :key="index">
          <span :class="data.activeIndex == index ? 'active' : ''"></span>
        </template>
      </div>
    </div>
    <div class="part_4">
      <img :src="data.url + 'brandStory/story_4.png'" alt="" class="bg" />
      <div class="text_info">回首过去</div>
      <div class="text_title">展望未来</div>
      <div class="downLoadBtn" @click="openPdf">
        查阅下载完整版《如新品牌故事》
        <img :src="data.url + 'brandStory/file.png'" alt="" />
      </div>
    </div>
    <div class="backBtn" @click="backIndex">
      <img :src="data.url + 'backBtn.png'" alt="" />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { prviewVideoBox } from "../js/until";
import { reactive, onMounted } from "vue";
import { baiduTotal } from "../js/common.js";
import { Swiper, SwiperSlide } from "swiper/swiper-vue.mjs";
let mySwiper = null;
let data = reactive({
  url: "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/", //图片地址
  imgList: [
    "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/brandStory/img_1.png",
    "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/brandStory/img_2.png",
    "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/brandStory/img_3.png",
    "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/brandStory/img_4.png",
  ],
  activeIndex: 0, //当前图片下标
  tab:1,//tab下标
  videoUrl:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/brandStory/40th.mp4',//视频链接
  poster:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/videoImg/video1.png',
  videoObj:{
    1:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/brandStory/40th.mp4',
    2:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/brandStory/meng.mp4',
    3:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/brandStory/tiaozhan.mp4',
    4:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/brandStory/brand.mp4'
  },//视频地址
  qcodeObj:{
    1:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/brandStory/branchqr.png',
    2:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/brandStory/mengQR.png',
    3:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/brandStory/tiaozhanQR.png',
    4:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/brandStory/brandQr.png'
  },//二维码
  posterObj:{
    1:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/videoImg/video1.png',
    2:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/videoImg/mengCover.png',
    3:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/videoImg/tiaozhanCover.png',
    4:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/videoImg/brandCover.png'
  },//封面图
  wangpanPathObj:{
    1:'https://nudrive.cn.nuskin.com/file/24s3nl0dx6c51hh27yz7v23ca5jfu96p#',
    2:'https://nudrive.cn.nuskin.com/file/vksqkgk4ozbj3vyoog91l4wbfscwgrvb?path=NUSKIN%E6%A2%A6%E5%BC%80%E5%A7%8B%E7%9A%84%E5%9C%B0%E6%96%B9.mp4&_=1715055388676',
    3:'https://nudrive.cn.nuskin.com/file/vksqkgk4ozbj3vyoog91l4wbfscwgrvb?path=%E5%85%85%E6%BB%A1%E4%BF%A1%E5%BF%83%E7%9B%B4%E9%9D%A2%E6%8C%91%E6%88%98.mp4&_=1715055599612',
    4:'https://nudrive.cn.nuskin.com/file/k7q029ns9bysn768pvim7u9qio7c1nvd#'
  },//网盘地址
});
const router = useRouter();
//返回首页
let backIndex = () => {
  router.replace("/index");
};
//切换下标
let changeTab=(tab)=>{
  data.tab=tab;
  data.videoUrl=data.videoObj[data.tab];
  data.poster=data.posterObj[data.tab];
}
//轮播下标
let onSlideChange = (e) => {
  data.activeIndex = e.activeIndex;
};
//自动播放
let setSwiperRef = (swiper) => {
  mySwiper.value = swiper;
  autoplay();
};
//自动播放
let autoplay = () => {
  setInterval(() => {
    if (data.activeIndex == 5) {
      mySwiper.value.slideTo(0, 1);
    } else {
      mySwiper.value.slideNext();
    }
  }, 5000);
};
//查看视频
let lookVideo = () => {
  prviewVideoBox({
    videoUrl:data.videoObj[data.tab],
    qcodeUrl:data.qcodeObj[data.tab],
    poster:data.posterObj[data.tab],
    wangpanPath:data.wangpanPathObj[data.tab],
  });
};
//查看图片
// let lookImg=()=>{
//   preImgBox({
//     imgList:data.imgList,
//     diskUrl:''
//   })
// }
//打开pdf
let openPdf = () => {
  window.location.href =
    "https://nudrive.cn.nuskin.com/file/yp3vvpe1ge0lq2wfhvjtfs4m21cxpzyy#";
};
onMounted(() => {
  baiduTotal();
});
</script>
<style lang="scss" scoped>
.brandStory {
  width: 100%;
  height: 100%;
  position: relative;
  background: #4b6794;
  box-sizing: border-box;
  overflow-y: auto;
  .part_1 {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.47rem;
    .logo {
      position: relative;
      width: 2.34rem;
      z-index: 2;
    }
    .title {
      position: relative;
      margin-top: 0.72rem;
      width: 100%;
      color: #fff;
      text-align: center;
      font-family: Source Han Serif SC;
      font-size: 0.26rem;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      z-index: 2;
    }
    .storyText {
      width: 100%;
      position: relative;
      z-index: 2;
      margin-top: 0.6rem;
      div {
        width: 100%;
        color: #fff;
        text-align: center;
        font-family: PingFang SC;
        font-size: 0.16rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.4rem; /* 250% */
        letter-spacing: 1.28px;
      }
    }
    .bg {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .bg2 {
      width: 100%;
      position: absolute;
      top: 2rem;
      left: 0;
      z-index: 1;
    }
  }
  .mortIcon {
    position: relative;
    width: 0.18rem;
    margin: 0.32rem 0;
  }
  .tab{
    position: relative;
    width: 3.5rem;
    height: 0.48rem;
    margin: 0 auto 0.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba($color: #fff, $alpha: 0.3);
    border-radius: 0.2rem;
    background:radial-gradient(
            at top center,
            rgba(197, 234, 255, 0.2),
            rgba(197, 234, 255, 0),
            rgba(197, 234, 255, 0)
          ),
    linear-gradient(
        98deg,
        rgba(122, 189, 228, 0),
        rgba(122, 189, 228, 0.4)
      ),#4C6895;
    box-shadow: 0 4px 8px 0 rgba(187,212,221,0.6);
    .tabItem{
      flex: 1;
      height: 0.48rem;
      text-align: center;
      font-size: 0.14rem;
      color: #fff;
      font-weight: bold;
      z-index: 2;
      position: relative;
      padding-top: 6px;
      box-sizing: border-box;
    }
    .selected{
      color: #4C6895;
    }
    .tabBg1{
      position: absolute;
      top: -0.04rem;
      left: -0.02rem;
      height: 0.52rem;
      z-index: -1;

    }
    .tabBg2{
      position: absolute;
      bottom: -1px;
      left: -0.3rem;
      height: 0.52rem;
      z-index: -1;
      transform: scaleX(-1);
    }
    .tabBg3{
      position: absolute;
      top: -0.04rem;
      right: -0.02rem;
      height: 0.52rem;
      z-index: -1;
      transform: scaleX(-1);
    }
  }
  .part_2 {
    width: 100%;
    position: relative;
    z-index: 1;
    .video {
      width: 100%;
      height: 2.1rem;
    }
    .downLoad {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 0.14rem;
      .bg {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
      .name {
        position: relative;
        color: #fff;
        text-align: center;
        font-family: PingFang SC;
        font-size: 0.12rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.6px;
      }
      .downLoadBtn {
        position: relative;
        width: 1.52rem;
        height: 0.4rem;
        margin-top: 0;
        border: 1px solid rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.12rem;
        font-style: normal;
        font-weight: 400;
        border-radius: 0.2rem;
      }
    }
  }
  .part_3 {
    width: 100%;
  }
  .part_4 {
    position: relative;
    width: 100%;
    height: 1.2rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0.32rem;
    padding-bottom: 0.6rem;
    margin-top: 0.4rem;
    .bg {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .text_info{
      position: relative;
      width: 1.1rem;
      height: 0.44rem;
      margin:0 auto 16px;
      border-radius:0.08rem;
      transform: skewX(-12deg);
      background: linear-gradient(60deg,#F9FDFF,#E2F0F6);
      font-family: PingFang SC;
      font-size: 0.18rem;
      font-style: normal;
      font-weight: bold;
      color: #4C6895;
      text-align: center;
      line-height: 0.44rem;
      box-shadow: 0 4px 6px rgba($color: #BEDFEC, $alpha: 0.5);
      &::after{
        content:'';
        position: absolute;
        bottom: -0.05rem;
        left: 50%;
        width: 0.1rem;
        height: 0.1rem;
        background: linear-gradient(60deg,#F9FDFF,#E2F0F6);
        transform:translateX(-50%) rotate(45deg);
        box-shadow: 0 4px 6px rgba($color: #BEDFEC, $alpha: 0.5);

      }
      z-index: 10;
    }
    .text_title{
      text-align: center;
      font-family: PingFang SC;
      font-size: 0.18rem;
      font-style: normal;
      font-weight: bold;
      color: #fff;
      z-index: 10;
      letter-spacing: 6px;
      margin-bottom: 0.2rem;
    }
    .downLoadBtn {
      margin-top:0;
      position: relative;
      width: 2.6rem;
      height: 0.4rem;
      border: 1px solid rgba(255, 255, 255, 0.5);
      text-align: center;
      line-height: 0.4rem;
      color: #fff;
      font-family: PingFang SC;
      font-size: 0.12rem;
      font-style: normal;
      font-weight: 400;
      border-radius: 0.2rem;
      img {
        height: 0.16rem;
        margin-left: 0.04rem;
      }
    }
  }
  .backBtn {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 1.17rem;
    height: 1.28rem;
    font-size: 0;
    z-index: 10;
    img {
      width: 100%;
    }
  }
}
.lunbo {
  position: relative;
  height: 2.25rem;
  .firstImg {
    position: absolute;
    top: 50%;
    left: -0.56rem;
    transform: translateY(-50%) scale(0.8);
  }
  .lastImg {
    position: absolute;
    top: 50%;
    right: -0.56rem;
    transform: translateY(-50%) scale(0.8);
  }
}
.swiper {
  margin-top: 0.2rem;
}
.swiper-slide {
  width: 1.62rem !important;
  text-align: center;
  transition: 300ms;
  transform: scale(0.8);
  img {
    width: 100%;
    border-radius: 0.08rem;
  }
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
  position: relative;
  img {
    box-shadow: 0 0.06rem 0.2rem rgba($color: #7abde4, $alpha: 0.6);
  }
}
.pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 0.2rem;
  span {
    width: 8px;
    height: 4px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    margin-right: 3px;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      width: 14px;
      background: #fff;
    }
  }
}
</style>
